import request from '@/utils/request'


// 查询意见反馈列表
export function listFeedback(query) {
  return request({
    url: '/user/feedback/list',
    method: 'get',
    params: query
  })
}

// 查询意见反馈分页
export function pageFeedback(query) {
  return request({
    url: '/user/feedback/page',
    method: 'get',
    params: query
  })
}

// 查询意见反馈详细
export function getFeedback(data) {
  return request({
    url: '/user/feedback/detail',
    method: 'get',
    params: data
  })
}

// 新增意见反馈
export function addFeedback(data) {
  return request({
    url: '/user/feedback/add',
    method: 'post',
    data: data
  })
}

// 修改意见反馈
export function updateFeedback(data) {
  return request({
    url: '/user/feedback/update',
    method: 'post',
    data: data
  })
}

// 删除意见反馈
export function delFeedback(data) {
  return request({
    url: '/user/feedback/delete',
    method: 'post',
    data: data
  })
}
